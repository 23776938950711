import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import KPIChart from "./KPIChart";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const cards = [
  {
    title: "TOTAL PUBLIC CONDOMS",
    query: {
      measures: ["distributions.public_total"],
      timeDimensions: [
        {
          dimension: "distributions.distributed_at",
          granularity: null,
          // dateRange: "Last year",
        },
      ],
      filters: [
        {
          member: "distributions.year",
          operator: "equals",
          values: ["2018"],
        },
      ],
    },
    duration: 1.75,
  },
  {
    title: "TOTAL SOCIAL MARKETING",
    query: {
      measures: ["distributions.social_marketing_total"],
      timeDimensions: [
        {
          dimension: "distributions.distributed_at",
          granularity: null,
          // dateRange: "Last year",
        },
      ],
      filters: [
        {
          member: "distributions.year",
          operator: "equals",
          values: ["2018"],
        },
      ],
    },
    duration: 1.75,
  },
  {
    title: "TOTAL COMMERCIAL",
    query: {
      measures: ["distributions.commercial_total"],
      timeDimensions: [
        {
          dimension: "distributions.distributed_at",
          granularity: null,
          // dateRange: "Last year",
        },
      ],
      filters: [
        {
          member: "distributions.year",
          operator: "equals",
          values: ["2018"],
        },
      ],
    },
    duration: 1.75,
  },
  {
    title: "TOTAL MALE",
    query: {
      measures: ["distributions.male"],
      timeDimensions: [
        {
          dimension: "distributions.distributed_at",
          granularity: null,
          // dateRange: "Last year",
        },
      ],
      filters: [
        {
          member: "distributions.year",
          operator: "equals",
          values: ["2018"],
        },
      ],
    },
    duration: 1.75,
  },
  {
    title: "TOTAL FEMALE",
    query: {
      measures: ["distributions.female"],
      timeDimensions: [
        {
          dimension: "distributions.distributed_at",
          granularity: null,
          // dateRange: "Last year",
        },
      ],
      filters: [
        {
          member: "distributions.year",
          operator: "equals",
          values: ["2018"],
        },
      ],
    },
    duration: 1.75,
  },
  {
    title: "TOTAL LUBRICANTS",
    query: {
      measures: ["distributions.lubricant"],
      timeDimensions: [
        {
          dimension: "distributions.distributed_at",
          granularity: null,
          // dateRange: "Last year",
        },
      ],
      filters: [
        {
          member: "distributions.year",
          operator: "equals",
          values: ["2018"],
        },
      ],
    },
    duration: 1.75,
  },
];

const KPIHeader = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        {cards.map((item, index) => {
          return (
            <Grid key={item.title + index} item lg={2} sm={2} xl={2} xs={12}>
              <KPIChart {...item} />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default KPIHeader;
