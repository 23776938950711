import React from "react";
import { ThemeProvider } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import { Layout } from "antd";
import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks";
import { ApolloProvider } from "react-apollo";
import cubejs from "@cubejs-client/core";
import { CubeProvider } from "@cubejs-client/react";
import theme from './theme';
import client from "./graphql/client";
import Header from './components/Header';

const AppLayout = ({ location, children }) => (
  <ThemeProvider theme={theme}>
    <Layout style={{ height: "100%" }}>
      <Header location={location} />
      <Layout.Content>{children}</Layout.Content>
    </Layout>
  </ThemeProvider>
);

const cubejsToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MDA4MzM3MDB9.2HAHBv6hQgHWcf2Ss_ZLupGy6nWx_6YoKcb1vUGLOtw'

const cubejsApi = cubejs(cubejsToken, {
  apiUrl: process.env.REACT_APP_API_URL
});

const App = withRouter(({ location, children }) => (
  <CubeProvider cubejsApi={cubejsApi}>
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>
          <AppLayout location={location}>{children}</AppLayout>
      </ApolloHooksProvider>
    </ApolloProvider>
  </CubeProvider>
));

export default App;
