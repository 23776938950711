import React from "react";
import { Row, Col  } from "antd";
import styled from 'styled-components';
// import { Link } from "react-router-dom";

const StyledRow = styled(Row)`
  padding: 23px 28px 13px 28px;
  background: white;`
  
// const ButtonsCol = styled(Col)`
//   text-align: right;`

const MenuCol = styled(Col)`
  font-size: 24px;
  font-weight: bold; 
  text-align: right;`

const PageHeader = ({ title, button, noBorder }) => (
  <StyledRow>
    <Col span={12}>
      {title}
    </Col>
    <MenuCol span={12}>
      <a href="https://condoms.aidsmalawi.org.mw/backend/">Login</a>
    </MenuCol>
  </StyledRow>
);

export default PageHeader;
