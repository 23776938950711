/* globals window */
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { SchemaLink } from "apollo-link-schema";
import { makeExecutableSchema } from "graphql-tools";
const cache = new InMemoryCache();
const defaultDashboardItems = [
  {
    vizState:
      '{"query":{"measures":["distributions.quantity"],"timeDimensions":[{"dimension":"distributions.distributed_at","granularity":"month"}],"dimensions":["brands.type"]},"chartType":"line","sessionGranularity":"month"}',
    name: "By type",
    id: "101",
    layout: '{"x":0,"y":0,"w":12,"h":8}',
  },
  {
    vizState:
      '{"query":{"measures":["distributions.quantity"],"timeDimensions":[{"dimension":"distributions.distributed_at","granularity":"month"}],"dimensions":["organisations.type"]},"chartType":"bar","sessionGranularity":"month"}',
    name: "By sector",
    id: "102",
    layout: '{"x":13,"y":0,"w":12,"h":8}',
  },
  {
    vizState:
      '{"query":{"measures":["distributions.quantity"],"dimensions":["brands.name"]},"chartType":"pie","sessionGranularity":"month"}',
    name: "By brand",
    id: "103",
    layout: '{"x":0,"y":8,"w":12,"h":8}',
  },
  {
    vizState:
      '{"query":{"measures":["distributions.quantity"],"dimensions":["districts.name"]},"chartType":"pie","sessionGranularity":"month"}',
    name: "By district",
    id: "104",
    layout: '{"x":13,"y":8,"w":12,"h":8}',
  },
  {
    vizState:
      '{"query":{"measures":["distributions.quantity"],"timeDimensions":[{"dimension":"distributions.distributed_at","granularity":"month"}],"dimensions":["districts.region"]},"chartType":"bar","sessionGranularity":"month"}',
    name: "By region",
    id: "105",
    layout: '{"x":0,"y":16,"w":12,"h":8}',
  },
  {
    vizState:
      '{"query":{"measures":["distributions.quantity"],"timeDimensions":[{"dimension":"distributions.distributed_at","granularity":"month"}],"dimensions":["zones.name"]},"chartType":"line","sessionGranularity":"month"}',
    name: "By zone",
    id: "106",
    layout: '{"x":13,"y":16,"w":12,"h":8}',
  },
  {
    vizState:
      '{"query":{"measures":["distributions.quantity"],"timeDimensions":[{"dimension":"distributions.distributed_at","granularity":"month"}],"dimensions":["organisations.name"]},"chartType":"bar","sessionGranularity":"month"}',
    name: "By partner",
    id: "107",
    layout: '{"x":0,"y":24,"w":12,"h":8}',
  },
  {
    vizState:
      '{"query":{"measures":["distributions.quantity"],"timeDimensions":[{"dimension":"distributions.distributed_at","granularity":"month"}],"dimensions":["brands.type"]},"chartType":"line","sessionGranularity":"month"}',
    name: "Distribution trend",
    id: "108",
    layout: '{"x":13,"y":24,"w":12,"h":8}',
  },
];

export const getDashboardItems = () =>
  defaultDashboardItems ||
  JSON.parse(window.localStorage.getItem("dashboardItems"));

export const setDashboardItems = (items) =>
  window.localStorage.setItem("dashboardItems", JSON.stringify(items));

const nextId = () => {
  const currentId =
    parseInt(window.localStorage.getItem("dashboardIdCounter"), 10) || 1;
  window.localStorage.setItem("dashboardIdCounter", currentId + 1);
  return currentId.toString();
};

const toApolloItem = (i) => ({ ...i, __typename: "DashboardItem" });

const typeDefs = `
  type DashboardItem {
    id: String!
    layout: String
    vizState: String
    name: String
  }

  input DashboardItemInput {
    layout: String
    vizState: String
    name: String
  }

  type Query {
    dashboardItems: [DashboardItem]
    dashboardItem(id: String!): DashboardItem
  }

  type Mutation {
    createDashboardItem(input: DashboardItemInput): DashboardItem
    updateDashboardItem(id: String!, input: DashboardItemInput): DashboardItem
    deleteDashboardItem(id: String!): DashboardItem
  }
`;
const schema = makeExecutableSchema({
  typeDefs,
  resolvers: {
    Query: {
      dashboardItems() {
        const dashboardItems = getDashboardItems();
        return dashboardItems.map(toApolloItem);
      },

      dashboardItem(_, { id }) {
        const dashboardItems = getDashboardItems();
        return toApolloItem(dashboardItems.find((i) => i.id.toString() === id));
      },
    },
    Mutation: {
      createDashboardItem: (_, { input: { ...item } }) => {
        const dashboardItems = getDashboardItems();
        item = { ...item, id: nextId(), layout: JSON.stringify({}) };
        dashboardItems.push(item);
        setDashboardItems(dashboardItems);
        return toApolloItem(item);
      },
      updateDashboardItem: (_, { id, input: { ...item } }) => {
        const dashboardItems = getDashboardItems();
        item = Object.keys(item)
          .filter((k) => !!item[k])
          .map((k) => ({
            [k]: item[k],
          }))
          .reduce((a, b) => ({ ...a, ...b }), {});
        const index = dashboardItems.findIndex((i) => i.id.toString() === id);
        dashboardItems[index] = { ...dashboardItems[index], ...item };
        setDashboardItems(dashboardItems);
        return toApolloItem(dashboardItems[index]);
      },
      deleteDashboardItem: (_, { id }) => {
        const dashboardItems = getDashboardItems();
        const index = dashboardItems.findIndex((i) => i.id.toString() === id);
        const [removedItem] = dashboardItems.splice(index, 1);
        setDashboardItems(dashboardItems);
        return toApolloItem(removedItem);
      },
    },
  },
});
export default new ApolloClient({
  cache,
  link: new SchemaLink({
    schema,
  }),
});
